import React from "react"
import HeaderV2 from "../headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import ClientLogos from "../../microcomponents/clientlogos/clientlogos"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import Button from "../../microcomponents/button/button"
import { ArrowIcon } from "../../microcomponents/icons"
import Image from "../../components/image-component/image-2023"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"

export default function FullServiceTeamV2() {
  return (
    <div>
      <HeaderV2 version="v2" />
      <Hero
        version="v2"
        className="greeneclipse"
        breadcrumb={[
          { name: "Home", url: "/" },
          {
            name: "How <span class='hidemobile'>it Works</span>",
            url: "/how-it-works/",
          },
          { name: "Full Service Team", url: null },
        ]}
        subHeading={null}
        heading="Our CRO team <br class='hidedesktop hidetablet hideipad hidemobile'/> <span>handles all <br class='hidedesktop hidetablet hidemobile'/> the <br class='hideipad'/> details</span> <br class='hidedesktop hidetablet hideipad hidemobile'/> for you."
        description="<p>Get more tests live without tying up <br class='hidedesktop hidetablet hideipad hidemobile'/> internal<br class='hidedesktop hideipad hidemobile'/> resources with a <br class='hidedesktop hidetablet hidemobile'/> dedicated <br class='hidedesktop hidetablet hideipad hidemobile'/> team of CRO <br class='hideipad hidemobile'/> specialists handling <br class='hidedesktop hidetablet hideipad hidemobile'/> everything for you. </p>"
        tags={[
          "Research",
          "Copywriting",
          "Design",
          "Development",
          "Analytics",
          "Project Management",
        ]}
        heroImage="image-interior-hero_2.webp"
        sourceFolder="spiralyze2024website"
        cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/v1710252838/spzinternal-website"
      />
      <ClientLogos version="v2 gray-bg" />
      <LeftRightImageText
        mainHeading="Discover what stops visitors <br class='hidedesktop hidemobile'/> from <br class='hidedesktop hidetablet hideipad'/> converting."
        subHeading="Research"
        sectionDescription="<p>Our research team uncovers the <br class='hidedesktop hidetablet hideipad'/> motivation gaps, <br class='hidedesktop hidemobile'/> objections, and ambiguities blocking conversions on<br class='hidetablet hideipad'/> your <br class='hidedesktop hidemobile'/> site. 
        Interviews, analytics, exit <br class='hidedesktop hidetablet hideipad'/> polling, session recording, <br class='hidedesktop hidemobile'/> heatmaps, review mining, message testing, competitor <br class='hidedesktop hidemobile'/> analysis, and more.</p><p>Custom research adds a layer of <br class='hidedesktop hidetablet hideipad'/> qualitative data to <br class='hidedesktop hidemobile'/> the insights from <br class='hidedesktop hidetablet hideipad'/> our<br class='hidetablet hideipad hidemobile'/> <a href='/prediction-engine/' class='blue'>prediction engine.</a></p>"
        // innerClasses="pd-56-tablet-top"
        // wrapperClasses="absolute-image-section"
        innerClasses="absolute-image-section"
        wrapperClasses="pd-56-tablet-top"
        id="research"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`imagery-desktop-02_1.webp`}
              // imgName={`image-research-desktop_4.webp`}
              alt={`Research`}
              imgName={[
                {
                  imageName: "imagery-desktop-02_1.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "image_56.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "image_61.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/pricing/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Learn More
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="High-converting copy written <br class='hidedesktop hidemobile'/> by industry specialists."
        subHeading="COPYWRITING & WIREFRAMING"
        sectionDescription="<p>Our copywriters are specialists,  <br class='hidetablet hideipad hidemobile'/>  with an understanding <br class='hidedesktop hidemobile'/> of  your <br class='hidetablet hideipad hidemobile'/> industry, personas, and <br class='hidedesktop hidetablet hideipad hidemobile'/> what works.  <br class='hidemobile'/> Digital security, FinTech,<br class='hidedesktop hidetablet hideipad hidemobile'/> HR, <br class='hidetablet hideipad hidemobile'/> DevOps, MarTech,<br class='hidedesktop hidemobile'/> and 17 other  <br class='hidetablet hideipad hidemobile'/> verticals.</p><p>Instead of enduring hours explaining  <br class='hidetablet hideipad hidemobile'/>  SOC 2 to an art <br class='hidedesktop hidemobile'/> history major — get  <br class='hidetablet hideipad hidemobile'/>  high-converting copy that sails  <br class='hidemobile'/>  through product, brand, and legal.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        innerClasses="reverse absolute-image-section"
        wrapperClasses="gray-bg pd-56-tablet-top"
        id="copywriting"
        imageComponent={
          <div className="">
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`imagery-desktop-03_1.webp`}
              // imgName={`image-research-desktop_4.webp`}
              alt={`Research`}
              imgName={[
                {
                  imageName: "imagery-desktop-03_1.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "image_63.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "image_57.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </div>
        }
      >
        <Button
          version={"v2"}
          href="/pricing/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Learn More
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="On brand, looks great, and <br class='hidedesktop hidemobile'/> high converting."
        subHeading="Design"
        sectionDescription="<p>Our designers act like an extension of<br class='hidetablet hideipad hidemobile'/> your team. We <br class='hidedesktop hidemobile'/> create pages that are <br class='hidetablet hideipad hidemobile'/> both on-brand and conversion-focused. <br class='hidedesktop hidemobile'/> We work across SaaS styles from minimalist to glass-morphic.</p><p>Designs that get everyone excited  <br class='hidemobile'/> about testing. </p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        innerClasses="absolute-image-section"
        wrapperClasses="pd-56-tablet-top"
        id="design"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              alt={`Design`}
              fallBackImage={`imagery-desktop-04_1.webp`}
              imgName={[
                {
                  imageName: "imagery-desktop-04_1.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "image_55.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "image_62.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/pricing/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Learn More
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="From ideas to fully built-out <br class='hidedesktop hidemobile'/> tests in  <br class='hidetablet hideipad hidemobile'/>  one week."
        subHeading="Development & QA"
        sectionDescription="<p>Fast development from 47 A/B testing <br class='hidedesktop hidetablet hideipad hidemobile'/> dev specialists. We <br class='hidedesktop hidemobile'/> have experience <br class='hidedesktop hidetablet hideipad hidemobile'/> across A/B testing platforms <br class='hidedesktop hidetablet hideipad hidemobile'/> (Optimizely,<br class='hidedesktop hidemobile'/> VWO, Convert, Mutiny, <br class='hidedesktop hidetablet hideipad hidemobile'/> etc.). We can also develop directly <br class='hidedesktop hidemobile'/> in <br class='hidedesktop hidetablet hideipad hidemobile'/> your CMS (WordPress, Marketo, <br class='hidedesktop hidetablet hideipad hidemobile'/> HubSpot, Unbounce,<br class='hidedesktop hidemobile'/> and more).</p><p>Dedicated QA, so it is pixel-perfect <br class='hidedesktop hidetablet hideipad hidemobile'/> every time. We even <br class='hidedesktop hidemobile'/> hard-code your winners. </p><p>We have developed over 12,000 tests <br class='hidedesktop hidetablet hideipad hidemobile'/> for 300+ major <br class='hidedesktop hidemobile'/> brands.<p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        innerClasses="reverse absolute-image-section"
        wrapperClasses="gray-bg pd-56-tablet-top"
        id="development"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              alt={`Development`}
              fallBackImage={`imagery-desktop-05_1.webp`}
              imgName={[
                {
                  imageName: "imagery-desktop-05_1.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "image_51.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "image_58.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/pricing/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Learn More
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Measure traffic, conversion, <br class='hidedesktop hidemobile'/> and funnel revenue."
        subHeading="Analytics"
        sectionDescription="<p>Build and validate test instrumentation <br class='hidedesktop hidetablet hideipad hidemobile'/> so you can <br class='hidedesktop hidemobile'/> measure all the most <br class='hidedesktop hidetablet hideipad hidemobile'/> important points along your <br class='hidedesktop hidemobile'/> conversion funnel. MQL, SQL, closed <br class='hidedesktop hidetablet hideipad hidemobile'/> deal, free-to-paid, <br class='hidedesktop hidemobile'/> upsells, AOV, and <br class='hidedesktop hidetablet hideipad hidemobile'/> more.</p><p>Get testing data into your backend systems, so you have a <br class='hidedesktop hidemobile'/> single source <br class='hidedesktop hidetablet hideipad hidemobile'/> of truth all the way through the funnel.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        innerClasses="absolute-image-section"
        wrapperClasses="pd-56-tablet-top"
        id="analytics"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              alt={`Analytics`}
              fallBackImage={`imagery-desktop-06_2.webp`}
              imgName={[
                {
                  imageName: "imagery-desktop-06_2.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "image_54.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "image_59.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/pricing/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Learn More
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="Everything on track and <br class='hidedesktop'/> organized."
        subHeading="Project Management"
        sectionDescription="<p>Your project manager is your central <br class='hidedesktop hidetablet hideipad hidemobile'/> point of contact.<br class='hidedesktop hidemobile'/> They maintain the strategic roadmap and plan ahead <br class='hidedesktop hidemobile'/> so you are running the right <br class='hidedesktop hidetablet hideipad hidemobile'/> tests and lots of them.</p><p>Your project manager coordinates communication <br class='hidedesktop hidemobile'/> between teams and documents, test plans, learnings, <br class='hidemobile'/>  and results.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        innerClasses="reverse absolute-image-section"
        wrapperClasses="gray-bg pd-56-tablet-top"
        id="projectmanagement"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              alt={`Project Management`}
              fallBackImage={`imagery-desktop-07.webp`}
              // imgName={`image_35.webp`}
              imgName={[
                {
                  imageName: "imagery-desktop-07.webp",
                  minBreakpoint: "1025px",
                },
                {
                  imageName: "image_53.webp",
                  minBreakpoint: "768px",
                },
                {
                  imageName: "image_60.webp",
                  minBreakpoint: "250px",
                },
              ]}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/pricing/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Learn More
        </Button>
      </LeftRightImageText>
      <TopPredictions version="v2" />
      <ClientResults version="v2" />
    </div>
  )
}
